<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/chairs">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 40% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/120.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CLEANROOM CHAIR
 


                </h4>
                <h5 style="text-align: center">SINT-05</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/chairs">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 40% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/121.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CLEANROOM STOOL



                </h4>
                <h5 style="text-align: center">SINT-06</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/chairs">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 40% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/122.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CLEANROOM STOOL
 


                </h4>
                <h5 style="text-align: center">SINT-07</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/chairs">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 40% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/123.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 CLEAN ROOM CHAIR
 


                </h4>
                <h5 style="text-align: center">SINT-08</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>